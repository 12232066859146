<template>
  <!-- 聯絡我們 -->
  <teleport to="#modalWrap">
    <div>
      <div
        :class="$style[`shadow`]"
        @click="setIsPaymentModalActive(false)"
      />
      <div
        class="modal-lg position-fixed top-50 start-50 translate-middle"
        :class="$style[`modal`]"
      >
        <div class="modal-content pop-up rounded-0">
          <div class="d-flex justify-content-end">
            <Icon
              role="button"
              name="cross"
              class="m-3"
              :class="$style[`icon`]"
              aria-label="Close"
              @click="setIsPaymentModalActive(false)"
            />
          </div>
          <div
            class="modal-body text-center px-3 px-md-5 pb-5"
            :class="$style[`modal-body`]"
          >
            <h5 class="col-12 fw-bold mb-3">
              匯款通知
            </h5>
            <!-- <p>
              謝謝您的主動聯絡，<br
                class="d-inline-block d-md-none"
              >請留下要諮詢的問題，我們將盡快與您聯繫。
            </p> -->
            <div class="col-12 px-4">
              <label class="text-start fw-bold col-12 m-2">匯款日期</label>
              <div
                v-for="field in fieldList"
                :key="field"
              >
                <Datepicker
                  v-if="field === 'payerDate'"
                  v-model="state[field]"
                  class="form-control bg-transparent mb-3"
                  :class="{ 'border-danger': v$[field]?.$error }"
                  :input-format="'yyyy-MM-dd'"
                  :clearable="false"
                  placeholder="請輸入匯款日期"
                  :style="{
                    '--vdp-selected-bg-color': '#cbbbae',
                    '--vdp-hover-bg-color': '#d4c4b7'
                  }"
                  @focus="v$[field].$touch"
                />
                <Input
                  v-else
                  :id="field"
                  v-model:input="state[field]"
                  class="mb-4"
                  :type="field"
                  :required="true"
                  :autocomplete="'off'"
                  :label="labelMap[field].label"
                  :placeholder="labelMap[field].placeholder"
                  :err-message="v$[field]?.$errors[0]?.$message || ''"
                  @focus="v$[field].$touch"
                  @enter="updatePayment"
                />
              </div>
            </div>
            <div class="col-12 px-4">
              <button
                id="contactBtn"
                class="btn btn-primary text-white ms-2 m-3"
                type="button"
                style="border-radius: 100px; width: 180px; height: 58px"
                @click="updatePayment"
              >
                送出
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, ref, inject, toRef } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { requiredHelper } from '@/hooks/useVuelidate.js'
import Input from '@/components/Input'
import Datepicker from 'vue3-datepicker'
// import Textarea from '@/components/Textarea'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  components: {
    Input,
    Datepicker
    // Textarea
  },
  props: {
    orderID: {
      type: String,
      default: ''
    }
  },
  emits: ['setIsPaymentModalActive'],
  setup (props, { emit }) {
    const { getters, dispatch } = useStore()
    const { getErrorMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])
    const setAlert = inject('setAlert')

    const orderID = toRef(props, 'orderID')
    const showContactModal = ref(false)

    const fieldList = ref(['payerDate', 'payerAccountCode', 'payer'])

    const state = ref({
      payerDate: new Date(),
      // amount: '',
      payerAccountCode: '',
      payer: ''
    })

    const labelMap = {
      payerDate: {
        label: '日期',
        placeholder: '請輸入匯款日期'
      },
      // amount: {
      //   label: '金額',
      //   placeholder: '請輸入匯款金額'
      // },
      payerAccountCode: {
        label: '末五碼',
        placeholder: '請輸入你的匯款帳號末五碼'
      },
      payer: {
        label: '匯款人姓名',
        placeholder: '請輸入你的姓名'
      }
    }

    const rules = {
      payerDate: { requiredHelper },
      // amount: { requiredHelper, emailHelper },
      payerAccountCode: { requiredHelper },
      payer: { requiredHelper }
    }

    const v$ = useVuelidate(rules, state)

    const clearState = () => {
      state.value = {
        payerDate: '',
        payerAccountCode: '',
        payer: ''
      }
      v$.value.$reset()
    }

    const setIsPaymentModalActive = () => {
      // let newValue
      // if (typeof show !== 'object') {
      //   newValue = show
      // } else {
      //   newValue = !showContactModal.value
      // }
      emit('setIsPaymentModalActive')
      // to open modal
      // if (newValue) {
      clearState()
      // }
      showContactModal.value = !showContactModal.value
    }

    // 送出聯絡表單
    const updatePayment = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        const payload = state.value

        if (memberID.value) {
          payload.memberID = memberID.value
          payload.orderID = orderID.value
        }

        payload.payerDate = `${new Date(payload.payerDate).getFullYear()}-${
          new Date(payload.payerDate).getMonth() + 1
        }-${new Date(payload.payerDate).getDate()}`

        try {
          const response = await dispatch('order/updatePayment', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, '傳送成功！我們將盡速與您聯絡。')
            setIsPaymentModalActive(false)
          }

          return response
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
          setAlert(true, false, `傳送失敗: ${error}`)
        }
      }
    }

    return {
      v$,
      labelMap,
      memberID,
      state,
      fieldList,
      showContactModal,
      setIsPaymentModalActive,
      updatePayment
    }
  }
}
</script>
<style lang="scss" module>
.icon {
  fill: currentColor;
  height: 0.9rem;
  width: 0.9rem;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

.modal {
  max-width: 600px;
  width: 80vw;
  z-index: $zindex-modal;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
