<template>
  <!-- 進行中訂單列表 -->
  <div>
    <div class="bg-earth text-white shadow rounded-2 position-relative p-3 mb-3">
      <div>
        匯款資訊<br>
        <div>{{ paywaysList }}</div>
        <div>
          <ContactModal
            v-if="isPaymentModalActive"
            :order-i-d="selectedOrderId"
            @setIsPaymentModalActive="setIsPaymentModalActive"
          />
          <CancelModal
            v-if="isPaymentModalActiveCancel"
            :order-i-d="selectedOrderId"
            @setIsPaymentModalActiveCancel="setIsPaymentModalActiveCancel"
          />
        </div>
      </div>
    </div>
    <div>
      <div
        class="bg-white position-relative rounded-2 p-4 shadow"
        :class="$style[`minHeight50vh`]"
      >
        <div
          v-if="ordersList.length !== 0"
          class="mb-3"
        >
          <div
            class="row col-12 align-items-center mx-auto py-3 px-md-0 bg-earth text-white"
          >
            <div class="text-md-start col col-md-3">
              訂購日期
            </div>
            <div class="text-center col flex-grow-1">
              訂單金額
            </div>
            <div class="text-center col flex-grow-1">
              訂單狀態
            </div>
            <div class="text-center col flex-grow-1">
              操作
            </div>
          </div>
          <div
            v-for="(row, index) in ordersList"
            :key="index"
            class="p-2 border-bottom"
            :class="{'shadow': showOrders?.[row.orderID]}"
          >
            <div
              class="d-flex align-items-center justify-content-between p-2 py-md-3"
              role="button"
              @click="setShowOrders(row.orderID)"
            >
              <div
                class="d-none d-lg-inline text-md-start text-start col col-md-3 px-0"
              >
                {{ row.insertTime ?? '-' }}
              </div>
              <div
                class="d-inline d-lg-none text-md-start text-start col col-md-3 px-0"
              >
                {{ row.insertTime ? row.insertTime.split(' ')[0] : '-' }}
              </div>
              <div class="text-center col flex-grow-1">
                $ {{ row.payAmt ?? 0 }}
              </div>
              <div
                v-if="row.status == '訂單成立'"
                class="text-center text-success col flex-grow-1 px-0"
              >
                {{ row.status ?? '-' }}
              </div>
              <div
                v-else-if="row.status == '已付款'"
                class="text-center text-success col flex-grow-1 px-0"
              >
                {{ row.status ?? '-' }}
              </div>
              <div
                v-else-if="row.status == '完成訂單'"
                class="text-center text-success col flex-grow-1 px-0"
              >
                {{ row.status ?? '-' }}
              </div>
              <div
                v-else-if="row.status == '訂單處理中'"
                class="text-center text-danger col flex-grow-1 px-0"
              >
                {{ row.status ?? '-' }}
              </div>
              <div
                v-else-if="row.status == '待付款'"
                class="text-center text-danger col flex-grow-1 px-0"
              >
                {{ row.status ?? '-' }}
              </div>
              <div
                v-else-if="row.status == '訂單作廢'"
                class="text-md-center text-grey text-start col flex-grow-1 px-0"
              >
                {{ row.status ?? '-' }}
              </div>
              <div class="text-end col flex-grow-1 px-0">
                <button
                  v-if="row.status == '訂單作廢'"
                  class="btn btn-grey text-white rounded-0 mb-1 mb-xxl-0 me-xxl-2"
                  type="button"
                  disabled
                  @click="setIsPaymentModalActive(row.orderID)"
                >
                  通知已匯款
                </button>
                <button
                  v-else
                  class="btn btn-earth text-white rounded-0 mb-1 mb-xxl-0 me-xxl-2"
                  type="button"
                  @click="setIsPaymentModalActive(row.orderID)"
                >
                  通知已匯款
                </button>
                <button
                  v-if="row.status == '訂單成立'"
                  class="btn btn-outline-earth rounded-0"
                  type="button"
                  @click="setIsPaymentModalActiveCancel(row.orderID)"
                >
                  取消訂單
                </button>
                <button
                  v-else-if="row.status == '待付款'"
                  class="btn btn-outline-earth rounded-0"
                  type="button"
                  @click="setIsPaymentModalActiveCancel(row.orderID)"
                >
                  取消訂單
                </button>
                <button
                  v-else-if="row.status == '已付款'"
                  class="btn btn-outline-earth rounded-0"
                  type="button"
                  @click="setIsPaymentModalActiveCancel(row.orderID)"
                >
                  取消訂單
                </button>
                <button
                  v-else-if="row.status == '訂單處理中'"
                  class="btn btn-outline-grey text-grey rounded-0"
                  type="button"
                  disabled
                  @click="setIsPaymentModalActiveCancel(row.orderID)"
                >
                  取消訂單
                </button>
                <button
                  v-else-if="row.status == '完成訂單'"
                  class="btn btn-outline-grey text-grey rounded-0"
                  type="button"
                  disabled
                  @click="setIsPaymentModalActiveCancel(row.orderID)"
                >
                  取消訂單
                </button>
                <button
                  v-else-if="row.status == '訂單作廢'"
                  class="btn btn-outline-grey text-grey rounded-0"
                  type="button"
                  disabled
                  @click="setIsPaymentModalActiveCancel(row.orderID)"
                >
                  取消訂單
                </button>
              </div>
            </div>
            <div
              class="w-100 bg-white"
              :class="[
                [$style['order']],
                {[$style['active']]: showOrders?.[row.orderID] }
              ]"
            >
              <div class="container-lg py-2 border">
                <div class="text-start mb-4">
                  訂單編號：<div class="d-inline-block bg-earth text-white p-2 rounded-2">
                    {{ row.orderID }}
                  </div>
                </div>
                <div class="text-start mb-4">
                  配送狀態：<span
                    v-if="row.shipStatus == '待寄件'"
                    class="text-danger"
                  >{{ row.shipStatus ?? '-' }}</span>
                  <span
                    v-else-if="row.shipStatus == '已出貨'"
                    class="text-success"
                  >{{ row.shipStatus ?? '-' }}</span>
                  <span
                    v-else
                  >{{
                    row.shipStatus ?? '-'
                  }}</span>
                </div>
                <div class="row align-items-center text-earth my-lg-2 d-flex">
                  <div class="col-4">
                    商品說明
                  </div>
                  <div class="text-end col-4 col-lg-2">
                    定價
                  </div>
                  <div class="text-end col-4 col-lg-2">
                    數量
                  </div>
                  <div class="text-end col-4 col-lg-2 d-none d-lg-block">
                    購物金折抵
                  </div>
                  <div class="text-end col-4 col-lg-2 d-none d-lg-block">
                    折價券折抵
                  </div>
                </div>
                <div
                  v-for="(detail, detailIndex) in row.orderDetails"
                  :key="detailIndex"
                  class="row align-items-center mb-2"
                >
                  <div class="col-4">
                    <a
                      :href="`/product/detail/${detail.productID}`"
                      target="_blank"
                      class="link-earth"
                    >
                      {{ detail.name ?? '-' }}
                    </a>
                  </div>
                  <div
                    class="text-end col-4 col-lg-2"
                  >
                    {{ detail.fixedPrice ?? 0 }}
                  </div>
                  <div
                    class="text-end col-4 col-lg-2"
                  >
                    {{ detail.qty ?? 0 }}
                  </div>
                  <div class="text-end col-4 col-lg-2 text-danger d-none d-lg-block">
                    - {{ detail.bonusAssign ?? 0 }}
                  </div>
                  <div class="text-end col-4 col-lg-2 text-danger d-none d-lg-block">
                    - {{ detail.redeemAmt ?? 0 }}
                  </div>
                </div>
                <hr>
                <div class="row mb-3">
                  <span class="col-8">小計</span>
                  <span class="col-4 text-end">{{ row.amount ?? 0 }}</span>
                </div>
                <div class="row mb-3">
                  <span class="col-8">運費</span>
                  <span class="col-4 text-end"> {{ row.shipFee ?? 0 }}</span>
                </div>
                <div
                  v-if="row.couponUse"
                  class="row mb-3"
                >
                  <span class="col-8">使用折扣碼</span>
                  <span
                    class="col-4 text-end text-danger"
                  >- {{ row.couponUse ?? 0 }}</span>
                </div>
                <div
                  v-if="row.bonusUse"
                  class="row mb-3"
                >
                  <span class="col-8">使用購物金折抵</span>
                  <span
                    class="col-4 text-end text-danger"
                  >- {{ row.bonusUse ?? 0 }}</span>
                </div>
                <hr>
                <div class="row mb-3">
                  <span class="col-8">支付金額</span>
                  <span class="col-4 text-end">$ {{ row.payAmt }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--empty msg-->
        <div
          v-if="ordersList.length === 0"
          class="position-absolute top-50 start-50 translate-middle d-flex flex-column"
        >
          <span class="d-block mb-2"> 目前還沒有訂單唷</span>
          <router-link
            to="/product/list"
            class="btn btn-earth text-white rounded-0"
          >
            馬上去逛逛
          </router-link>
        </div>

        <!-- page nav -->
        <PagePanigation
          v-if="!isLoading && ordersList.length !== 0"
          class="d-none d-md-flex"
          :current-page="page?.current"
          :total-page="page?.totalPage"
          @setPage="setPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, inject, toRef, ref, reactive, watch } from 'vue'
import PagePanigation from '@/components/PagePanigation'
import ContactModal from '@/components/ContactModal'
import CancelModal from '@/components/CancelModal'

export default {
  name: 'MemberOrders',
  components: {
    PagePanigation,
    ContactModal,
    CancelModal
  },
  props: {
    showComplete: {
      type: Boolean,
      default: false
    }
  },
  async setup (props) {
    const { getters, dispatch } = useStore()

    // const orderID = computed(() => getters['order/getOrderID'])

    const showComplete = toRef(props, 'showComplete')
    const showOrders = ref({})
    const setShowOrders = (orderID) => {
      showOrders.value[orderID] = !showOrders.value[orderID]
    }

    const isPaymentModalActive = ref(false)
    const isPaymentModalActiveCancel = ref(false)
    const setIsPaymentModalActive = (ID) => {
      if (ID) {
        setSelectedOrderId(ID)
      }
      isPaymentModalActive.value = !isPaymentModalActive.value
    }
    const setIsPaymentModalActiveCancel = (ID) => {
      if (ID) {
        setSelectedOrderId(ID)
      }
      isPaymentModalActiveCancel.value = !isPaymentModalActiveCancel.value
    }

    const selectedOrderId = ref(null)
    const setSelectedOrderId = (ID) => {
      selectedOrderId.value = ID
    }

    const page = reactive({
      current: 1,
      totalPage: 1,
      totalElement: 0
    })
    const pageSize = ref(20)

    const isLoading = computed(() => getters.getIsLoading)
    const memberID = computed(() => getters['member/getMemberID'])
    const ordersAll = computed(() => getters['member/getOrdersAll'])
    const paywaysBank = computed(() => getters['payment/getPaywaysBank'])

    const setAlert = inject('setAlert')

    const paywaysList = computed(() => {
      return paywaysBank.value
    })

    const ordersList = computed(() => {
      return ordersAll.value
    })

    const setPage = (page) => {
      readOrdersAll(page)
      readPaywaysBank(page)
      // showComplete.value ? readOrdersCompleted(page) : readOrdersInProcess(page)
    }

    const readPaywaysBank = async (newPage) => {
      const payload = {}

      try {
        await dispatch('payment/readPaywaysBank', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    const cancelOrder = async (orderID) => {
      // reload()
      const payload = {
        orderID: orderID,
        statusID: 5
      }
      try {
        const response = await dispatch('order/cancelOrder', {
          payload
        })
        readOrdersAll()
        // getErrorMessage
        return response
      } catch (error) {}
    }

    function reload () {
      location.reload()
    }

    const readOrdersAll = async (newPage) => {
      const payload = {
        memberID: memberID.value,
        page: newPage || page.current,
        offset: page.current * pageSize.value
      }

      try {
        await dispatch('member/readOrdersAll', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    watch(
      showComplete,
      async () => {
        showOrders.value = {}
        await readOrdersAll()
        await readPaywaysBank()
        // if (showComplete.value) {
        //   await readOrdersCompleted()
        // } else {
        //   await readOrdersInProcess()
        // }
      },
      { immediate: true }
    )

    return {
      ordersList,
      isLoading,
      setPage,
      page,
      showOrders,
      setShowOrders,
      paywaysList,
      cancelOrder,
      reload,
      isPaymentModalActive,
      isPaymentModalActiveCancel,
      selectedOrderId,
      setIsPaymentModalActive,
      setIsPaymentModalActiveCancel
    }
  }
}
</script>
<style lang="scss" module>
.order {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.order.active {
  max-height: 1000px;
}

.down {
  width: 0.6rem;
  height: 0.6rem;
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.down.active {
  transform: rotate(270deg);
}

.minHeight50vh {
  min-height: 50vh;
}
</style>
